// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .XxYS8R1NdJt5j_J9Jsel {
        display: flex;
        flex-grow: 1;
    }

    .avP4UqFe4TXTci11brPF {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1 1 0;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/grid/src/components/CenterBox.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,YAAY;IAChB;;IAEA;QACI,aAAa;QACb,uBAAuB;QACvB,mBAAmB;QACnB,sBAAsB;QACtB,WAAW;IACf;AACJ","sourcesContent":["@layer packages {\n    .centerBox {\n        display: flex;\n        flex-grow: 1;\n    }\n\n    .centerBoxContent {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        flex-direction: column;\n        flex: 1 1 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var centerBox = `XxYS8R1NdJt5j_J9Jsel`;
export var centerBoxContent = `avP4UqFe4TXTci11brPF`;
export default ___CSS_LOADER_EXPORT___;
