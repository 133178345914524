// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .hQoTfWdOLvkh0YmApvKD{
        --iconSize: 1;
        transform: scale(var(--iconSize));
        width: 10px;
        height: 10px;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/icons/src/components/FlagIcon.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,iCAAiC;QACjC,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":["@layer packages {\n    .flagIcon{\n        --iconSize: 1;\n        transform: scale(var(--iconSize));\n        width: 10px;\n        height: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var flagIcon = `hQoTfWdOLvkh0YmApvKD`;
export default ___CSS_LOADER_EXPORT___;
