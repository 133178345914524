// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .CCOfIcUwtUcozSHKVRHQ {
        display: block;
        position: relative;
    }

    .I4kO6ACui4wyMqmgEC7w {
        width: 100%;
    }

    .OGKWDUlG3t8JJQ3peuIa {
        --aspect-ratio: auto;
        --width: auto;
        --height: auto;

        aspect-ratio: var(--aspect-ratio);
        height: var(--height);
        width: var(--width);
    }

    .rYlsFPQfc2UleNl9twO_ {
        height: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/picture/src/components/Picture.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,cAAc;QACd,kBAAkB;IACtB;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,oBAAoB;QACpB,aAAa;QACb,cAAc;;QAEd,iCAAiC;QACjC,qBAAqB;QACrB,mBAAmB;IACvB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":["@layer packages {\n    .pictureBase {\n        display: block;\n        position: relative;\n    }\n\n    .hasErrorModule {\n        width: 100%;\n    }\n\n    .pictureSize {\n        --aspect-ratio: auto;\n        --width: auto;\n        --height: auto;\n\n        aspect-ratio: var(--aspect-ratio);\n        height: var(--height);\n        width: var(--width);\n    }\n\n    .pictureFit {\n        height: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var pictureBase = `CCOfIcUwtUcozSHKVRHQ`;
export var hasErrorModule = `I4kO6ACui4wyMqmgEC7w`;
export var pictureSize = `OGKWDUlG3t8JJQ3peuIa`;
export var pictureFit = `rYlsFPQfc2UleNl9twO_`;
export default ___CSS_LOADER_EXPORT___;
