// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CCg_mmz_B3uD6vRf19nB {
    --highlight-color: var(--colors-primary);

    overflow: hidden;
    background-image: linear-gradient(var(--highlight-color), var(--highlight-color) 30%, transparent 30%);
    background-repeat: no-repeat;
    background-position: 0 0.8em;
    position: relative;
    font-weight: 700;

    animation: TOxtuVf304IznUCJhNtG 1.5s linear;

    @keyframes TOxtuVf304IznUCJhNtG {
        from {
            background-position: -100em 0.8em;
        }
        to {
            background-position: 0 0.8em;
        }
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/text/src/components/Highlight.module.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;;IAExC,gBAAgB;IAChB,sGAAsG;IACtG,4BAA4B;IAC5B,4BAA4B;IAC5B,kBAAkB;IAClB,gBAAgB;;IAEhB,2CAAgC;;IAEhC;QACI;YACI,iCAAiC;QACrC;QACA;YACI,4BAA4B;QAChC;IACJ;AACJ","sourcesContent":[".highlight {\n    --highlight-color: var(--colors-primary);\n\n    overflow: hidden;\n    background-image: linear-gradient(var(--highlight-color), var(--highlight-color) 30%, transparent 30%);\n    background-repeat: no-repeat;\n    background-position: 0 0.8em;\n    position: relative;\n    font-weight: 700;\n\n    animation: underline 1.5s linear;\n\n    @keyframes underline {\n        from {\n            background-position: -100em 0.8em;\n        }\n        to {\n            background-position: 0 0.8em;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var highlight = `CCg_mmz_B3uD6vRf19nB`;
export var underline = `TOxtuVf304IznUCJhNtG`;
export default ___CSS_LOADER_EXPORT___;
