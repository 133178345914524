import {css, Theme, useTheme} from "@emotion/react";

import {backgroundColor, display, flexAbsoluteCenter, ml, onDesktop, position} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {IStats} from "../../properties/types/IRecommendationsState";
import {getListCount} from "../hooks/get_list_count";
import {NavigationLinkType} from "../types/NavigationLinkType";
import {LinkIcon} from "./LinkIcon";

interface IProps {
    type: NavigationLinkType;
    label: string;
    stats: IStats;
}

export const LinkWithCount = (props: IProps) => {
    const {type, label, stats} = props;
    const theme = useTheme();
    const statistics = getListCount(type, stats);

    return (
        <>
            <LinkIcon type={type} />
            {label}
            {!!statistics.count && (
                <Text as="span" variant="info_txt_2" css={[theme.colors.gray[700], {fontWeight: 400}]}>
                    {` (${statistics.count})`}
                </Text>
            )}
            {!!statistics.new && (
                <span css={noticeStyle}>
                    <Text as="span" variant="info_txt_1" strong>
                        {statistics.new}
                    </Text>
                </span>
            )}
        </>
    );
};

const noticeStyle = (theme: Theme) => css`
    ${flexAbsoluteCenter};
    ${display("inline-flex")};
    ${position("absolute")};
    top: -30%;
    width: 2rem;
    height: 2rem;
    color: #fff;
    border-radius: 50%;
    ${ml(0.5)};
    ${backgroundColor(theme.colors.danger)};

    ${onDesktop(css`
        top: -10%;
    `)}
`;
