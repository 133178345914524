import {css} from "@emotion/react";

import {fontFace, globals, normalize, resetStyles} from "@pg-design/styles";
import {themeVariableListGh, themeVariableListKm, themeVariableListRp} from "@pg-design/styles-strings";

import {ITheme} from "../../hooks/use_matched_theme";
import {STATIC_PATH} from "../constants/paths";

const fontVariant = "poppins";
const fontPath = `${STATIC_PATH}/fonts/${fontVariant}/`;

export const globalClassNames = {
    RP: "theme-rp",
    GH: "theme-gh"
};

export const getGlobalStyles = (theme: ITheme) => css`
    html {
        ${themeVariableListKm}
    }

    html.${globalClassNames.RP} {
        ${themeVariableListRp}
    }

    html.${globalClassNames.GH} {
        ${themeVariableListGh}
    }

    ${resetStyles}

    ${fontFace(fontPath, fontVariant, `${fontVariant}_light`, 300)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_regular`, 400)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_medium`, 500)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_semibold`, 600)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_bold`, 700)};

    ${normalize};
    ${globals(theme)};

    a:hover,
    a:focus {
        outline: 0;
    }

    html,
    body,
    #root {
        min-height: 100vh;
    }

    .ReactModal__Body--open {
        overflow: hidden;
        padding-right: 15px;
    }
`;
