
      import API from "!../../../../../../.yarn/__virtual__/style-loader-virtual-6affa7085e/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../../../.yarn/__virtual__/style-loader-virtual-6affa7085e/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../../../../.yarn/__virtual__/style-loader-virtual-6affa7085e/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../../../../.yarn/__virtual__/style-loader-virtual-6affa7085e/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../../../../.yarn/__virtual__/style-loader-virtual-6affa7085e/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../../../.yarn/__virtual__/style-loader-virtual-6affa7085e/0/cache/style-loader-npm-4.0.0-e0f957f3d6-93f25b7e70.zip/node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/cjs.js!./SvgIcon.module.css";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = insertFn.bind(null, "head");
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/cjs.js!./SvgIcon.module.css";
       export default content && content.locals ? content.locals : undefined;
