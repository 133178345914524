// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-19b6a060f0/0/cache/css-loader-npm-7.1.1-25b990b98a-435a21f195.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .JazsFa9_vhCDrHDOQ0QG {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin: 0 0 0 var(--globals-baseUnit);

        @media (min-width: 0) {
            margin: 0 calc(var(--grid-sm-gutterPx) / -2);
        }

        @media (min-width: 1024px) {
            margin: 0 calc(var(--grid-md-gutterPx) / -2);
        }

        @media (min-width: 1366px) {
            margin: 0 calc(var(--grid-lg-gutterPx) / -2);
        }
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/grid/src/components/Row.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,eAAe;QACf,eAAe;QACf,qCAAqC;;QAErC;YACI,4CAA4C;QAChD;;QAEA;YACI,4CAA4C;QAChD;;QAEA;YACI,4CAA4C;QAChD;IACJ;AACJ","sourcesContent":["@layer packages {\n    .row {\n        display: flex;\n        flex-wrap: wrap;\n        padding-left: 0;\n        margin: 0 0 0 var(--globals-baseUnit);\n\n        @media (min-width: 0) {\n            margin: 0 calc(var(--grid-sm-gutterPx) / -2);\n        }\n\n        @media (min-width: 1024px) {\n            margin: 0 calc(var(--grid-md-gutterPx) / -2);\n        }\n\n        @media (min-width: 1366px) {\n            margin: 0 calc(var(--grid-lg-gutterPx) / -2);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var row = `JazsFa9_vhCDrHDOQ0QG`;
export default ___CSS_LOADER_EXPORT___;
