import React from "react";
import {css, Theme, useTheme} from "@emotion/react";
import {Formik} from "formik";
import * as Yup from "yup";

import {Button} from "@pg-design/button";
import {FormikForm, IFormikSubmitFn} from "@pg-design/formik-utils";
import {borderRadius, calculateRemSize, flex, flexDirection, mb, mt, onDesktop, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    title: string;
    text?: string;
    options: {
        value: string;
        label: string;
    }[];
    allowOwnAnswer?: boolean;
    ownAnswerLabel?: string;
    onSubmit: (values: {answers: string[]; ownAnswer?: string}) => Promise<void> | void;
}

const emptyInitialValues: {[key in string]: boolean} | {ownAnswer: string; showOwnAnswerInput: boolean} = {
    ownAnswer: "",
    showOwnAnswerInput: false
};
const validationSchema = Yup.object().shape({
    ownAnswer: Yup.string().min(3)
});

export const ModalSimpleSurveyLayout = (props: IProps) => {
    const {title, text, options, allowOwnAnswer, ownAnswerLabel} = props;
    const theme = useTheme();

    const onSubmit: IFormikSubmitFn<typeof emptyInitialValues> = async (values, formikHelpers) => {
        const {showOwnAnswerInput, ownAnswer, ...selectedAnswersObject} = values;

        const answersKeys = Object.keys(selectedAnswersObject);
        const answersValues = Object.values(selectedAnswersObject);
        const answers = answersKeys.filter((answer, index) => answersValues[index]);

        await props.onSubmit({
            answers: showOwnAnswerInput ? answers.concat("OTHER") : answers,
            ...(showOwnAnswerInput ? {ownAnswer} : {})
        });
        formikHelpers.setSubmitting(false);
    };

    return (
        <div css={innerWrap}>
            <Text variant="headline_3" css={[generalTextStyle, mb(2), onDesktop(mb(0.5))]}>
                {title}
            </Text>

            {text ? (
                <Text as="div" variant="body_copy_2" color={theme.colors.gray[700]} css={[generalTextStyle]}>
                    {text}
                </Text>
            ) : null}

            <Formik onSubmit={onSubmit} initialValues={emptyInitialValues} validationSchema={validationSchema}>
                {(formikProps) => {
                    return (
                        <form css={mb(4)} onSubmit={formikProps.handleSubmit}>
                            <div css={[optionsWrapperStyle]}>
                                {options.map((option) => (
                                    <FormikForm.Checkbox
                                        key={option.value}
                                        name={option.value}
                                        id={option.value}
                                        labelContent={
                                            <Text as="span" variant="info_txt_1">
                                                {option.label}
                                            </Text>
                                        }
                                        css={optionStyle}
                                    />
                                ))}

                                {allowOwnAnswer ? (
                                    <div css={[flex(), flexDirection("column"), optionStyle]}>
                                        <FormikForm.Checkbox
                                            name="showOwnAnswerInput"
                                            id="showOwnAnswerInput"
                                            labelContent={
                                                <Text as="span" variant="info_txt_1">
                                                    {ownAnswerLabel ?? "Inny powód?"}
                                                </Text>
                                            }
                                        />
                                        {formikProps.getFieldProps("showOwnAnswerInput").value ? (
                                            <FormikForm.Textarea name="ownAnswer" id="ownAnswer" css={[mt(1)]} autoFocus />
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>

                            <div css={[flex("center", "center"), mt(4)]}>
                                <Button variant="filled_primary" css={buttonStyle} disabled={formikProps.isSubmitting}>
                                    Zapisz
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

const innerWrap = css`
    position: relative;
    max-height: 100vh;
    height: 100%;
    max-width: 100vw;
    overflow: auto;
    display: flex;
    flex-direction: column;
    ${p(7, 1.5, 1, 1.5)};

    ${onDesktop(css`
        width: 65rem;
        height: auto;
        ${p(4, 4, 1)};
        ${borderRadius()};
    `)};
`;

const generalTextStyle = css`
    text-align: center;

    ${onDesktop(css`
        text-align: left;
    `)};
`;

const optionsWrapperStyle = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(1)};
    ${mt(4)};
`;

const optionStyle = (theme: Theme) => css`
    ${p(1)};
    ${borderRadius()};
    background-color: ${theme.colors.gray[100]};
    width: 100%;
`;

const buttonStyle = css`
    width: 100%;

    ${onDesktop(css`
        width: auto;
        min-width: 23rem;
    `)};
`;
